<template>
  <div class="questionType">
    <van-cell title="设备状态">
      <!-- 使用 right-icon 插槽来自定义右侧图标 -->
      <template #right-icon>
        <van-tag type="success" v-if="multiCardDetail.onlineStatus == 1"
          >在线</van-tag
        >
        <van-tag type="danger" v-else>离线</van-tag>
      </template>
    </van-cell>
    <van-cell
      title="当前网络(点击可以切换)"
      is-link
      :value="tool.formatOperatorType(multiCardDetail.currentOperator)"
      @click="changeOperatorHandle"
    />
    <van-cell title="最近一次上下线时间" :value="multiCardDetail.onlineTime" />
    <van-action-sheet
      v-model:show="changeOperatorVisible"
      :actions="subCardList"
      title="切换网络"
      @select="selectOperator"
    />
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance, onMounted } from "vue";
import { querySubCardByMultiCardId, realNameUrl } from "_API_/api.services";
export default {
  setup() {
    const state = reactive({
      changeOperatorVisible: false,
      multiCardDetail: {},
      subCardList: []
    });
    // 这里的ctx  类似于vue2的this
    const { proxy: ctx } = getCurrentInstance();
    const { $tool: tool, $router: router, $store: store, $http: http } = ctx;
    const methods = {
      changeOperatorHandle() {
        state.changeOperatorVisible = true;
        state.subCardList = state.subCardList.filter(item => {
          if (item.operator == Number(state.multiCardDetail.currentOperator)) {
            item.disabled = true;
            item.color = tool.getThemeList(store.state.common.theme);
            item.subname = "在用";
          } else if (item.status == 1) {
            item.color = tool.getThemeList(store.state.common.theme);
            item.subname = "可激活";
          } else if (item.status == 2) {
            item.color = tool.getThemeList(store.state.common.theme);
            item.subname = "已激活";
          } else if (item.status == 6) {
            item.color = tool.getThemeList(store.state.common.theme);
            item.subname = "可测试";
          } else if (item.status == 11) {
            item.color = tool.getThemeList(store.state.common.theme);
            item.subname = "库存";
          } else {
            item.subname = "已停用，暂不可以切换";
            item.disabled = true;
            delete item.color;
          }
          return item;
        });
      },
      /**
       * @params realNameType 子卡通道实名类型  0-未勾选  1-主动实名 2-被动实名 3-强制实名
       * @params realNameStatus 子卡实名状态 0-否 1-全部实名 2-系统实名 3-三方系统实名 4-暂不实名 5-多网卡部分实名
       */
      selectOperator({ operator, realNameType, realNameStatus }) {
        // 未勾选 或 被动实名
        if (realNameType == 0 || realNameType == 2) {
          tool.confirm(
            {
              title: "温馨提醒",
              msg: "是否立即切换",
              confirmButtonText: "确定",
              cancelButtonText: "取消"
            },
            () => {
              ctx.switchNetwork(operator);
            },
            () => {
              state.changeOperatorVisible = false;
            }
          );
        }
        // 子卡通道实名类型为主动实名 且 子卡未实名
        if (realNameType == 1 && !Boolean(realNameStatus)) {
          tool.confirm(
            {
              title: "温馨提醒",
              msg: `当前${
                operator == 1 ? "电信" : operator == 2 ? "移动" : "联通"
              }卡号未实名，建议您尽快进行实名`,
              confirmButtonText: "继续切换",
              cancelButtonText: "去实名"
            },
            () => {
              ctx.switchNetwork(operator);
            },
            () => {
              router.push({
                name: "personal-authenTication"
              });
            }
          );
        } else if (realNameType == 1 && Boolean(realNameStatus)) {
          // 子卡通道实名类型为主动实名 且 子卡已实名
          ctx.switchNetwork(operator);
        }

        // 子卡通道实名类型为强制实名 且 子卡未实名
        if (realNameType == 3 && !Boolean(realNameStatus)) {
          tool.confirm(
            {
              title: "温馨提醒",
              msg: `当前${
                operator == 1 ? "电信" : operator == 2 ? "移动" : "联通"
              }卡号未实名，请您实名后再切换`,
              confirmButtonText: "去实名",
              cancelButtonText: "取消"
            },
            () => {
              router.push({
                name: "personal-authenTication"
              });
            },
            () => {
              state.changeOperatorVisible = false;
            }
          );
        } else if (realNameType == 3 && Boolean(realNameStatus)) {
          // 子卡通道实名类型为强制实名 且 子卡已实名
          ctx.switchNetwork(operator);
        }
      },
      //切换运营商
      switchNetwork(operator) {
        http
          .get("/card/switchNetworkByMultiCardId", {
            params: {
              multiCardIccid: store.state.user.userInfo.iccid,
              operators: operator
            }
          })
          .then(({ data }) => {
            if (data.code === 0) {
              return tool.toast(
                {
                  msg: "切换成功,请等待3分钟左右",
                  duration: 3000
                },
                () => {
                  ctx.MultiCardDetail();
                }
              );
            }
            tool.toast({
              msg: data.msg
            });
          });
        state.changeOperatorVisible = false;
      },
      // 查看多网卡详情
      MultiCardDetail() {
        http
          .get("/card/showMultiCardDetail", {
            params: {
              multiCardIccid: store.state.user.userInfo.iccid
            }
          })
          .then(({ data }) => {
            if (data.code === 0) {
              return Object.assign(state.multiCardDetail, data.data.detail);
            }
            tool.toast({
              msg: data.msg
            });
          });
      },
      //查询多网卡主子卡关系
      getSubCardByMultiCard(card) {
        querySubCardByMultiCardId(card).then(({ data: res }) => {
          state.subCardList = res.data.subCardList;
        });
      }
    };
    onMounted(() => {
      ctx.MultiCardDetail(),
        ctx.getSubCardByMultiCard(store.state.user.userInfo.iccid);
    });
    return {
      ...toRefs(state),
      ...methods,
      tool
    };
  }
};
</script>

<style lang="scss" scoped>
.questionType {
  width: 100%;
  background: #fff;
  padding-top: 15px;
}
</style>
